import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://b0aa3f8740a0943b12c19d5a9b900420@o406760.ingest.us.sentry.io/4508781164560384",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});